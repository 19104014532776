import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import '../css/contactForm.css';
import PhoneIcon from '../assets/circle.png';
import EmailIcon from '../assets/mail.png';
import AddressIcon from '../assets/location.png';
import SocialMedia from './SocialMedia';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const fade = useSpring({ opacity: 1, from: { opacity: 0 } });
  const [formData, setFormData] = useState({
    name: '',
    subject: '',
    phone: '',
    email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Sending email using EmailJS (you need to configure EmailJS)
  
    emailjs.send('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', formData, 'YOUR_USER_ID')
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
      }, (err) => {
        console.log('Failed to send email. Error:', err);
      });
    

    // Send WhatsApp message (This will open WhatsApp with pre-filled text)
    const whatsappURL = `https://wa.me/?text=${encodeURIComponent(
      `Name: ${formData.name}\nSubject: ${formData.subject}\nPhone: ${formData.phone}\nEmail: ${formData.email}\nMessage: ${formData.message}`
    )}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <animated.div style={fade} className="contact-form-container">
      <div className="contact-form-container">
        <div className="contact-head">
          <h1>CONTACT US</h1>
        </div>
        <div className="contact-form">
          <h3>Get in Touch</h3>
          <p>Please fill out the form below and we will get back to you as soon as possible.</p>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group form-group-half">
                <label htmlFor="name">Name</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
              </div>
              <div className="form-group form-group-half">
                <label htmlFor="subject">Subject</label>
                <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group form-group-half">
                <label htmlFor="phone">Phone</label>
                <input type="text" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
              </div>
              <div className="form-group form-group-half">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" value={formData.message} onChange={handleChange}></textarea>
            </div>
            <div className="form-buttons">
              <button type="submit" className="send-btn">Send</button>
              <button type="reset" className="reset-btn">Reset</button>
            </div>
          </form>
        </div>
      </div>

      <div className="contact-details">
        <div className="contact-item">
          <img src={PhoneIcon} alt="Phone" />
          <div>
            <h3>Phone</h3>
            <p>+91 9823915612</p>
          </div>
        </div>
        <div className="contact-item">
          <img src={EmailIcon} alt="Email" />
          <div>
            <h3>Email</h3>
            <p>yyf.ngo@gmail.com</p>
          </div>
        </div>
        <div className="contact-item">
          <img src={AddressIcon} alt="Address" />
          <div>
            <h3>Address</h3>
            <p>YEHOVA YIRE FOUNDATION 2nd floor, civil, warora naka, chandrapur 442401</p>
          </div>
        </div>
        <div className="contact-item">
          <div>
            <SocialMedia />
          </div>
        </div>
      </div>
    </animated.div>
  );
};

export default ContactForm;
