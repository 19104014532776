import React from 'react';
// import { Link } from 'react-router-dom';
import '../css/LandingPage.css'
// import landing from '../assets/LandImg.png'
import abt from '../assets/OBJECTIVE 2 (1).png'

const Objective = () => {
    return (

        <>
            <div className="about-us">
                <h1>OBJECTIVES</h1>
                <img src={abt} alt="" />

            </div>



        </>

    );
}

export default Objective;
