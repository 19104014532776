import React from 'react';

const Button1000 = ({ disabled })  => {
  const handlePayNowClick = () => {
    const paymentUrl = `${process.env.PUBLIC_URL}/Button1000.html`; // Adjust the path if needed
    window.open(paymentUrl);
  };

  return (
    

      <button onClick={handlePayNowClick} className="  bg-primary p-2  text-white fw-bold  " style={{borderRadius:'10px'}} >
       Donate ₹1000
      </button>
     
  );
};

export default Button1000;
